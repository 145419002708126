// App.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; 

function App() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [apiValue, setApiValue] = useState('');
    const [apiCreated, setApiCreated] = useState(false);
    const [apiError, setApiError] = useState('');

    useEffect(() => {
        // Check localStorage for login state on page load
        const isLoggedIn = localStorage.getItem('loggedIn');
        if (isLoggedIn === 'true') {
            setLoggedIn(true);
        }
    }, []);

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://admin928.rysedrc20.com/login', { username, password });
            if (response.data.success) {
                setLoggedIn(true);
                setErrorMessage('');
                // Store login state in localStorage
                localStorage.setItem('loggedIn', 'true');
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const createApi = async () => {
        try {
            const response = await axios.post('https://admin928.rysedrc20.com/create-api', { value: apiValue });
            if (response.data.success) {
                setApiCreated(true);
                setApiError('');
                setApiValue(''); // Clear apiValue state
            } else {
                setApiError(response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="login-container">
            {loggedIn ? (
                <div>
                    <h1>Welcome to the $Ryse admin panel</h1>
                    <div>
                        <input type="text" placeholder="0 - 100" value={apiValue} onChange={(e) => setApiValue(e.target.value)} />
                        <button className="create-api-button btn-ryze" onClick={createApi}>Update Percentage</button>
                        {apiCreated && <p className="api-success-message">Percentage updated successfully!</p>}
                        {apiError && <p className="api-error-message">{apiError}</p>}
                    </div>
                </div>
            ) : (
                <div>
                    <h1>Login</h1>
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <button className="login-button" onClick={handleLogin}>Login</button>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                </div>
            )}
        </div>
    );
}

export default App;
